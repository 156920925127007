import * as React from 'react';
import { graphql } from 'gatsby';

import { Template } from  'common/layout';
import { StudiosHeader, StudiosContent } from 'modules/StudiosPage';

const StudioOverview: React.FC<StudiosPageProps> = ({ data }) => {
  const cities = data?.allContentfulTypeCity?.edges;
  const studios = data?.allContentfulTypeStudio?.edges;

  return (
    <Template>
      <StudiosHeader />
      <StudiosContent
        studios={studios}
        cities={cities}
      />
    </Template>
  );
};

export default StudioOverview;

export const query = graphql`
  query StudiosPageQuery ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          language
          data
        }
      }
    }
    allContentfulTypeStudio(filter: {node_locale: {eq: $language}}){
      edges {
        node {
          name
          id
          slug
          node_locale
          zipCode
          telephoneNumber
          streetAddress
          emailaddress
          city {
            cityTitle
          }
          frontImage {
            file {
              url
            }
          }
        }
      }
    }
    allContentfulTypeCity(filter: {node_locale: {eq: $language}}) {
      edges {
        node {
          cityTitle
          node_locale
          studioOverviewImage {
            file {
              url
            }
          }
        }
      }
    }
  }
`;

type StudiosPageProps = {
  data: GatsbyTypes.StudiosPageQuery;
};
